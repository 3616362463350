export default [
  estudio(
    "AHORA TODO CAMBIO",
    "2019-01-06",
    "1° CORINTIOS 6: 11 y 12",
    "Y esto erais algunos; mas ya habéis sido lavados, ya habéis sido santificados, ya habéis sido justificados en el nombre del Señor Jesús, y por el Espíritu de nuestro Dios.",
    "01 ESTUDIO BIBLICO 2019.docx"
  ),
  estudio(
    "GLORIFICAD A DIOS EN VUESTRO CUERPO",
    "2019-01-20",
    "1° CORINTIOS 6: 13 y 14",
    "Las viandas para el vientre, y el vientre para las viandas; pero tanto al uno como a las otras destruirá Dios. Pero el cuerpo no es para la fornicación, sino para el Señor, y el Señor para el cuerpo.",
    "02 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "COMPRADOS POR PRECIO",
    "2019-01-27",
    "1° CORINTIOS 6: 19 y 20",
    "¿O ignoráis que vuestro cuerpo es templo del Espíritu Santo, el cual está en vosotros, el cual tenéis de Dios, y que no sois vuestros?",
    "03 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "DIOS HA ESTABLECIDO EL MATRIMONIO",
    "2019-02-03",
    "1° CORINTIOS 7: 2 y 3",
    "Pero a causa de las fornicaciones, cada uno tenga su propia mujer, y cada una tenga su propio marido.",
    "04 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "EL MATRIMONIO: CUANDO UN CÓNYUGE ES CREYENTE Y EL OTRO NO",
    "2019-02-10",
    "1° Corintios 7: 13 y 14",
    "Y si una mujer tiene marido que no sea creyente, y él consiente en vivir con ella, no lo abandone.",
    "05 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "SIRVIENDO A DIOS DONDE ÉL NOS HA COLOCADO",
    "2019-02-17",
    "1° Corintios 7: 19 y 20",
    "La circuncisión nada es, y la incircuncisión  nada es, sino el guardar los mandamientos de Dios.",
    "06 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "CRISTO NOS HA HECHO LIBRES",
    "2019-02-24",
    "1° Corintios 7: 22 y 23",
    "Porque el que en el Señor fue llamado siendo esclavo, liberto es del Señor; asimismo el que fue llamado siendo libre, esclavo es de Cristo.",
    "07 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "EL TIEMPO ES CORTO, CRISTO VIENE PRONTO",
    "2019-03-03",
    "1° Corintio 7: 28 y 29",
    "Mas también si te casas, no pecas; y si la doncella se casa, no peca; pero los tales tendrán   aflicción de la carne, y yo os la quisiera evitar.",
    "08 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "HEMOS DE ELEGIR LA BUENA PARTE",
    "2019-03-10",
    "1° Corintios 7: 31 Y 32",
    "Y los que disfrutan de este mundo, como si no lo disfrutasen; porque la apariencia de este mundo se pasa.",
    "09 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "SEAMOS SENSATOS",
    "2019-03-17",
    "1° Corintios 8: 6 y 7",
    "Para nosotros, sin embargo, sólo hay un Dios, el Padre, del cual proceden todas las cosas, y nosotros somos para él; y un Señor, Jesucristo, por medio del cual son todas las cosas, y nosotros por medio de él.",
    "10 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LAS LIMITALCIONES DE LA LIBERTAD CRISTIANA",
    "2019-03-24",
    "1° Corintios, 8: 12 y 13",
    "De esta manera, pues, pecando contra los hermanos e hiriendo su débil conciencia, contra Cristo pecáis.",
    "11 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "EL APÓSTOL VINDICA (AFIRMA) SU AUTORIDAD APOSTÓLIDA",
    "2019-03-31",
    "1° Corintios 9: 1 y 2",
    "¿No soy apóstol? ¿No soy libre? ¿No he visto a Jesús el Señor nuestro? ¿No sois vosotros mi obra en el Señor?",
    "12 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LOS DERECHOS DE UN APÓSTOL",
    "2019-04-07",
    "1° Corintos 9: 9 y 10",
    "Porque en la ley de Moisés está escrito: No pondrás bozal al buey que trilla. ¿Tiene Dios cuidado de los bueyes.",
    "13 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "SIRVAMOS AL SEÑOR DE BUENA VOLUNTAD",
    "2019-04-14",
    "1° Corintios 9: 16 y 17",
    "Pues si anuncio el evangelio, no tengo por qué gloriarme; porque me es impuesta necesidad; y ¡ay de mí si no anunciare el evangelio!",
    "14 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "EL MÉTODO DEL VERDADERO EVANGELIO",
    "2019-04-21",
    "1° CORINTIOS 9: 19 Y 20",
    "Por lo cual, siendo libre de todos, me he hecho siervo de todos para ganar a mayor número.",
    "15 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LA RESPONSABILIDAD DE GANAR A OTRO PARA CRISTO",
    "2019-04-28",
    "1° Corintios 9: 21 y 22",
    "A los que están sin ley, como si yo estuviera sin ley (no estando yo sin ley de Dios, sino bajo la ley de Cristo), para ganar a los que están sin ley.",
    "16 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "CORRED, LUCHAD, UN PREMIO NOS ESPERA",
    "2019-05-04",
    "1° Corintios 9: 24 y 25",
    "¿No sabéis que los que corren en el estadio, todos a la verdad corren, pero uno solo se lleva el premio? Corred de tal manera que lo obtengáis.",
    "17 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LA DISCIPLINA DEL CRISTIANO",
    "2019-05-12",
    "1° Corintios 9: 26 y 27",
    "Así que, yo de esta manera corro, no como a la ventura; de esta manera peleo, no como quien golpea el aire.",
    "18 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LA FALSA SEGURIDAD DE LOS JUDÍO, Y EL PELIGRO DE EXCESO DE CONFIANZA",
    "2019-05-19",
    "1° Corintios 10: 1 y 2",
    "Porque no quiero, hermanos, que ignoréis que nuestros padres todos estuvieron bajo la nube, y todos pasaron el mar.",
    "19 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LA COMIDA Y BEBIDA ESPIRITUAL",
    "2019-05-26",
    "1° Corintios 10: 4 y 5",
    "Y todos bebieron la misma bebida espiritual; porque bebían de la roca espiritual que los seguía, y la roca era Cristo.",
    "20 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "CUIDADO CON LA CODICIA Y LA IDOLATRÍA",
    "2019-06-02",
    "1° Corintios 10: 6 y 7",
    "Mas estas cosas sucedieron como ejemplos para nosotros, para que no codiciemos cosas malas, como ellos codiciaron.",
    "21 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "CUIDADO CON LA INMORALIDAD Y NO TENTAR AL SEÑOR",
    "2019-06-09",
    "1° Corintios 10: 8 y 9",
    "Ni forniquemos, como algunos de ellos fornicaron, y cayeron en un día veintitrés mil.",
    "22 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "NO MURMURAR (Quejarse)",
    "2019-06-16",
    "1° Corintios 10: 10 y 11",
    "Ni murmuréis, como algunos de ellos murmuraron, y perecieron por el destructor.",
    "23 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "CUIDADO CON LA TENTACIÓN HUMANO",
    "2019-06-23",
    "1° Corintios 10: 12 y 13",
    "Así que, el que piensa estar firme, mire que no caiga.",
    "24 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "HUID DE LA IDOLATRÍA",
    "2019-06-30",
    "1° Corintios 10: 14 y 15",
    "Por tanto, amados míos, huid de la idolatría.",
    "25 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "SOMOS PARTE DEL CUERPO DE CRISTO Y ESTO DEMANDA HONRARLE",
    "2019-07-07",
    "1° Corintios 10: 16 y 17",
    "La copa de bendición que bendecimos, ¿no es la comunión de la sangre de Cristo? El pan que partimos, ¿no es la comunión del cuerpo de Cristo?",
    "26 ESTUDIOS BIBLICOS 2019.docx"
  ),
  estudio(
    "LOS LÍMITES DE LA LIBERTAD CRISTIANA",
    "2019-07-14",
    "1° Corintios 10: 23 y 24",
    "Todo me es lícito, pero no todo conviene; todo me es lícito, pero no todo edifica.",
    "27 ESTUDIOS BIBLICOS 2019.docx"
  )
];

function estudio(title, date, versos, description, url) {
  return {
    title,
    date,
    versos,
    description,
    url
  };
}
